import { Fragment, useState } from "react";
import { Popover, Tab, Transition } from "@headlessui/react";
import { IoChevronDownOutline } from "react-icons/io5";
import { HiOutlineMenuAlt1 } from "react-icons/hi";
import { Link } from "react-router-dom";
import { FaEnvelope, FaFacebookF, FaInstagram } from "react-icons/fa";
import MobileHeader from "./MobileHeader";

const navigation = {
  arena: [
    {
      name: "Alto",
      link: "/vehicles/alto",
      img: require("../../assets/arena/alto.webp"),
    },
    {
      name: "Alto K10",
      link: "/vehicles/alto-k10",
      img: require("../../assets/arena/k10.webp"),
    },
    {
      name: "Brezza",
      link: "/vehicles/brezza",
      img: require("../../assets/arena/brezza.webp"),
    },
    {
      name: "Celerio",
      link: "/vehicles/celerio",
      img: require("../../assets/arena/celerio.webp"),
    },
    {
      name: "Dzire",
      link: "/vehicles/dzire",
      img: require("../../assets/arena/dzire.webp"),
    },
    {
      name: "EECO",
      link: "/vehicles/eeco",
      img: require("../../assets/arena/eeco.webp"),
    },
    {
      name: "Ertiga",
      link: "/vehicles/ertiga",
      img: require("../../assets/arena/ertiga.png"),
    },
    {
      name: "S-Presso",
      link: "/vehicles/s-presso",
      img: require("../../assets/arena/Spresso.webp"),
    },
    {
      name: "Swift",
      link: "/vehicles/swift",
      img: require("../../assets/arena/swift.webp"),
    },
    {
      name: "Wagon R",
      link: "/vehicles/wagonR",
      img: require("../../assets/arena/wagonr.png"),
    },
  ],
  nexa: [
    {
      name: "Invicto",
      link: "/vehicles/invicto",
      img: require("../../assets/nexa/invicto.webp"),
    },
    {
      name: "Fronx",
      link: "/vehicles/fronx",
      img: require("../../assets/nexa/fronx.png"),
    },
    {
      name: "Jimny",
      link: "/vehicles/jimny",
      img: require("../../assets/nexa/jimny.png"),
    },
    {
      name: "Baleno",
      link: "/vehicles/baleno",
      img: require("../../assets/nexa/baleno.png"),
    },
    {
      name: "Ciaz",
      link: "/vehicles/ciaz",
      img: require("../../assets/nexa/ciaz.webp"),
    },
    {
      name: "Ignis",
      link: "/vehicles/ignis",
      img: require("../../assets/nexa/ignis.webp"),
    },
    // {
    //   name: "S-Cross",
    //   link: "/vehicles/scross",
    //   img: require("../../assets/nexa/scross.webp"),
    // },
    {
      name: "XL6",
      link: "/vehicles/xl6",
      img: require("../../assets/nexa/xl6.webp"),
    },
    {
      name: "Grand Vitara",
      link: "/vehicles/grandvitara",
      img: require("../../assets/nexa/grandvitara.webp"),
    },
  ],
  commercial: [
    {
      name: "Super Carry",
      link: "/vehicles/super-carry",
      img: require("../../assets/goods/carry.webp"),
    },
    {
      name: "EECO Cargo",
      link: "/vehicles/eeco-cargo",
      img: require("../../assets/goods/cargo.webp"),
    },
  ],
  suzuki: [
    {
      name: "V-Storm SX",
      link: "/vehicles/v-storm",
      img: require("../../assets/v-storm/1.png"),
    },
    {
      name: "S-250/SF-250",
      link: "/vehicles/s-250",
      img: require("../../assets/s-250/1.png"),
    },
    {
      name: "Gixxer SF",
      link: "/vehicles/gixxer-sf",
      img: require("../../assets/sf/1.png"),
    },
    {
      name: "Hayabusa",
      link: "/vehicles/hayabusa",
      img: require("../../assets/hayabusa/1.jpg"),
    },
    {
      name: "Avenis",
      link: "/vehicles/avenis",
      img: require("../../assets/avenis/1.jpg"),
    },
    {
      name: "Access 125",
      link: "/vehicles/access-125",
      img: require("../../assets/access/1.png"),
    },
    {
      name: "Burgman Street",
      link: "/vehicles/burgman-street",
      img: require("../../assets/street/1.jpg"),
    },
  ],
  pages: [
    { name: "Home", to: "/" },
    { name: "About Us", to: "/about" },
  ],
  servicepages: [
    {
      name: "Book A Service",
      to: "/book-a-service",
    },
    {
      name: "Finance",
      to: "/finance",
    },
    {
      name: "Insurance",
      to: "/insurance",
    },
    {
      name: "Truevalue",
      to: "/truevalue",
    },
    // {
    //   name: "Driving School",
    //   to: "/driving-school-hyderabad",
    // },
  ],
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Header() {
  return (
    <>
      <TopHeader />
      <MainHeader />
      <MobileHeader />
    </>
  );
}

const TopHeader = () => {
  return (
    <div className="bg-red-600 text-white py-3 px-5 xl:px-0">
      <div className="max-w-screen-xl mx-auto sm:flex items-center sm:justify-between text-sm font-light">
        <div className="flex items-center space-x-2">
          <Link to="/book-a-service">Book a Service</Link>
          <div className="h-5 w-[1px] rounded bg-white"></div>
          <Link to="/offers">Offers</Link>
        </div>
        <div className="flex items-center space-x-3">
          <a href="mailto:srcg@gmail.com" className="flex items-center">
            <FaEnvelope className="mr-1" /> gemmaruti.com@gmail.com
          </a>
          <a
            href="https://www.facebook.com/GemMarutiOfficial/"
            target={"_blank"}
          >
            <FaFacebookF />
          </a>
          <a
            href="https://www.instagram.com/gem_motors_hyderabad/"
            target={"_blank"}
          >
            <FaInstagram />
          </a>
        </div>
      </div>
    </div>
  );
};

const MainHeader = () => {
  const [navbar, setNavbar] = useState(false);
  const fixedNavbar = () => {
    if (window.scrollY >= 100) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", fixedNavbar);
  return (
    <div
      className={` ${
        navbar
          ? " fixed top-0 z-20 w-full  shadow-2xl drop-shadow-2xl bg-white"
          : "border-b"
      } hidden lg:block px-5 xl:px-0`}
    >
      <header className="relative">
        <nav aria-label="Top" className="max-w-screen-xl mx-auto ">
          <div className="border-gray-200">
            <div className="h-16 flex items-center">
              {/* Logo */}
              <div className="mr-auto flex lg:ml-0 ml-2">
                <Link to="/">
                  <img
                    className="h-16 w-auto"
                    src={require("../../assets/logo.png")}
                    alt="logo"
                  />
                </Link>
              </div>

              <button
                type="button"
                className="p-2 rounded-md text-gray-900 lg:hidden"
              >
                <span className="sr-only">Open menu</span>
                <HiOutlineMenuAlt1 className="h-6 w-6" aria-hidden="true" />
              </button>

              {/* Flyout menus */}
              <Popover.Group className="hidden ml-auto lg:block lg:self-stretch">
                <div className="h-full flex space-x-8">
                  {navigation.pages.map((page) => (
                    <Link
                      key={page.name}
                      to={page.to}
                      className="flex items-center text-sm font-medium text-black hover:text-black"
                    >
                      {page.name}
                    </Link>
                  ))}

                  <Popover className="flex">
                    {({ open }) => (
                      <>
                        <div className="relative flex">
                          <Popover.Button
                            className={classNames(
                              open
                                ? "border-[#051641] text-[#051641]"
                                : "border-transparent text-black hover:text-black",
                              "relative z-10 flex items-center transition-colors ease-out duration-200 text-sm font-medium border-b-2 -mb-px pt-px outline-none focus:outline-none"
                            )}
                          >
                            Our Channels
                            <IoChevronDownOutline
                              className={classNames(
                                open ? "text-[#051641]" : "text-black",
                                "ml-1 h-4 w-4 group-hover:text-black"
                              )}
                            />
                          </Popover.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Popover.Panel className="absolute top-full inset-x-0 text-sm text-gray-500 shadow z-10">
                            <div
                              className="absolute inset-0 top-1/2 bg-white "
                              aria-hidden="true"
                            />

                            <div className="relative pt-6 bg-white">
                              <div className="max-w-screen-xl mx-auto px-8">
                                <Tab.Group>
                                  <div className="grid grid-cols-4">
                                    <div className="col-span-1">
                                      <Tab.List className="space-x-3">
                                        <Tab
                                          className={({ selected }) =>
                                            selected
                                              ? "bg-blue-600 text-white mb-3 rounded-lg outline-none max-w-[260px] w-full ml-3"
                                              : "bg-gray-200 rounded-lg outline-none text-black hover:bg-blue-400 hover:text-white mb-3 max-w-[260px] w-full ml-3"
                                          }
                                        >
                                          <p className="inline-flex py-4 px-4 text-sm font-medium text-center rounded-t-lg border-b-2 border-transparent group uppercase">
                                            Arena
                                          </p>
                                        </Tab>
                                        <Tab
                                          className={({ selected }) =>
                                            selected
                                              ? "bg-black text-white mb-3 rounded-lg outline-none max-w-[260px] w-full"
                                              : "bg-gray-200 rounded-lg outline-none text-black hover:bg-black hover:text-white mb-3 max-w-[260px] w-full"
                                          }
                                        >
                                          <p className="inline-flex py-4 px-4 text-sm font-medium text-center group uppercase ">
                                            Nexa
                                          </p>
                                        </Tab>
                                        <Tab
                                          className={({ selected }) =>
                                            selected
                                              ? "bg-red-600 text-white mb-3 rounded-lg outline-none max-w-[260px] w-full"
                                              : "bg-gray-200 rounded-lg outline-none text-black hover:bg-red-400 hover:text-white mb-3 max-w-[260px] w-full"
                                          }
                                        >
                                          <p className="inline-flex py-4 px-4 text-sm font-medium text-center group uppercase ">
                                            Commercial
                                          </p>
                                        </Tab>

                                        <Tab
                                          className={({ selected }) =>
                                            selected
                                              ? "bg-green-600 text-white mb-3 rounded-lg outline-none max-w-[260px] w-full"
                                              : "bg-gray-200 rounded-lg outline-none text-black hover:bg-green-400 hover:text-white mb-3 max-w-[260px] w-full"
                                          }
                                        >
                                          <p className="inline-flex py-4 px-4 text-sm font-medium text-center group uppercase ">
                                            Suzuki
                                          </p>
                                        </Tab>
                                      </Tab.List>
                                    </div>
                                    <div className="col-span-3 px-3">
                                      <Tab.Panels>
                                        {/* Arena */}
                                        <Tab.Panel>
                                          <div className="grid grid-cols-5 gap-4 gap-x-8 py-6">
                                            {navigation.arena.map((item, i) => (
                                              <div
                                                key={i}
                                                className="group relative text-base sm:text-sm"
                                              >
                                                <img
                                                  src={item.img}
                                                  alt={i}
                                                  className="object-center object-cover mx-auto h-[115px] rounded-lg group-hover:opacity-75"
                                                />
                                                <a
                                                  href={item.link}
                                                  className="mt-3 block text-center font-medium text-gray-900"
                                                >
                                                  <span
                                                    className="absolute z-10 inset-0"
                                                    aria-hidden="true"
                                                  />
                                                  {item.name}
                                                </a>
                                              </div>
                                            ))}
                                          </div>
                                        </Tab.Panel>

                                        {/* Nexa */}
                                        <Tab.Panel>
                                          <div className="grid grid-cols-4 gap-4 gap-x-8 py-6">
                                            {navigation.nexa.map((item, i) => (
                                              <div
                                                key={i}
                                                className="group relative text-base sm:text-sm"
                                              >
                                                <div className="aspect-w-1 aspect-h-1 rounded-lg bg-gray-100 overflow-hidden group-hover:opacity-75">
                                                  <img
                                                    src={item.img}
                                                    alt={i}
                                                    className="object-center object-cover mx-auto rounded-lg"
                                                  />
                                                </div>
                                                <a
                                                  href={item.link}
                                                  className="mt-3 block text-center font-medium text-gray-900"
                                                >
                                                  <span
                                                    className="absolute z-10 inset-0"
                                                    aria-hidden="true"
                                                  />
                                                  {item.name}
                                                </a>
                                              </div>
                                            ))}
                                          </div>
                                        </Tab.Panel>

                                        {/* Commercial */}
                                        <Tab.Panel>
                                          <div className="grid grid-cols-4 gap-4 gap-x-8 py-6">
                                            {navigation.commercial.map(
                                              (item, i) => (
                                                <div
                                                  key={i}
                                                  className="group relative text-base sm:text-sm"
                                                >
                                                  <div className="aspect-w-1 aspect-h-1 rounded-lg bg-gray-100 overflow-hidden group-hover:opacity-75">
                                                    <img
                                                      src={item.img}
                                                      alt={i}
                                                      className="object-center object-cover mx-auto rounded-lg"
                                                    />
                                                  </div>
                                                  <a
                                                    href={item.link}
                                                    className="mt-3 block text-center font-medium text-gray-900"
                                                  >
                                                    <span
                                                      className="absolute z-10 inset-0"
                                                      aria-hidden="true"
                                                    />
                                                    {item.name}
                                                  </a>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </Tab.Panel>

                                        {/* CNG */}
                                        <Tab.Panel>
                                          <div className="grid grid-cols-4 gap-4 gap-x-8 py-6">
                                            {navigation.suzuki.map(
                                              (item, i) => (
                                                <div
                                                  key={i}
                                                  className="group relative text-base sm:text-sm"
                                                >
                                                  <div className="aspect-w-1 aspect-h-1 rounded-lg bg-gray-100 overflow-hidden group-hover:opacity-75">
                                                    <img
                                                      src={item.img}
                                                      alt={i}
                                                      className="object-center object-cover mx-auto rounded-lg"
                                                    />
                                                  </div>
                                                  <a
                                                    href={item.link}
                                                    className="mt-3 block text-center font-medium text-gray-900"
                                                  >
                                                    <span
                                                      className="absolute z-10 inset-0"
                                                      aria-hidden="true"
                                                    />
                                                    {item.name}
                                                  </a>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </Tab.Panel>
                                      </Tab.Panels>
                                    </div>
                                  </div>
                                </Tab.Group>
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>

                  <Popover className="flex">
                    {({ open }) => (
                      <>
                        <div className="relative flex">
                          <Popover.Button
                            className={classNames(
                              open
                                ? "border-[#051641] text-[#051641]"
                                : "border-transparent text-black hover:text-black",
                              "relative z-10 flex items-center transition-colors ease-out duration-200 text-sm font-medium border-b-2 -mb-px pt-px outline-none focus:outline-none"
                            )}
                          >
                            Services
                            <IoChevronDownOutline
                              className={classNames(
                                open ? "text-[#051641]" : "text-black",
                                "ml-1 h-4 w-4 group-hover:text-black"
                              )}
                            />
                          </Popover.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Popover.Panel className="absolute top-full -ml-6 text-sm shadow rounded overflow-hidden w-full max-w-[130px]">
                            <div
                              className="absolute inset-0 top-1/2 bg-white"
                              aria-hidden="true"
                            />
                            {navigation.servicepages.map((item, index) => (
                              <Link to={item.to} key={index}>
                                <p className="relative p-3 hover:bg-gray-100 bg-white z-10">
                                  {item.name}
                                </p>
                              </Link>
                            ))}
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>

                  <Link
                    to="/outlets"
                    className="flex items-center text-sm font-medium "
                  >
                    Outlets
                  </Link>

                  {/* <Link
                    to="/careers"
                    className="flex items-center text-sm font-medium "
                  >
                    Careers
                  </Link> */}

                  <Link
                    to="/gallery"
                    className="flex items-center text-sm font-medium "
                  >
                    Gallery
                  </Link>

                  <Link
                    to="/contact-us"
                    className="flex items-center text-sm font-medium "
                  >
                    Contact
                  </Link>
                </div>
              </Popover.Group>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Header;
