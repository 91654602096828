import { useEffect } from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";

import Home from "./screens/Home";
import Detail from "./screens/Detail";
import Thankyou from "./screens/Thankyou";
import BookAService from "./screens/services/book";
import Finance from "./screens/services/finance";
import Insurance from "./screens/services/insurance";
import Truevalue from "./screens/services/truevalue";
import Contact from "./screens/contact";
import Channel from "./screens/Channel";
import Awards from "./screens/Awards";
import Gallery from "./screens/Gallery";
import Outlets from "./screens/Outlets";
import { MdDriveEta } from "react-icons/md";

import whatsappSvg from "./assets/whatsapp.svg";
import About from "./screens/About";
import Offers from "./screens/Offers";

function App() {
  const ScrollTop = () => {
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    return null;
  };
  return (
    <>
      <ScrollTop />
      <div className="hidden">
        Developed by Rajesh Allala Powered by SR Creative Graphics. Reach out to
        us: Satheesh Koppela 96522 98089
      </div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/offers" element={<Offers />} />
        <Route path="/vehicles/:id" element={<Detail />} />
        <Route path="/channels/:id" element={<Channel />} />
        <Route path="/book-a-service" element={<BookAService />} />
        <Route path="/finance" element={<Finance />} />
        <Route path="/insurance" element={<Insurance />} />
        <Route path="/truevalue" element={<Truevalue />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/thank-you" element={<Thankyou />} />
        <Route path="/awards" element={<Awards />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/outlets" element={<Outlets />} />
        <Route path="*" element={"404 - Not Found"} />
      </Routes>

      {/* <div className="fixed top-1/2 bottom-1/2 -right-32 duration-300 bg-white transition-all hover:right-0 z-50">
        <div className="flex items-center justify-center">
          <MdDriveEta size={24} />
          <Link to={"/book-a-service"}>Book a test drive</Link>
        </div>
        <div className="flex items-center justify-center">
          <MdDriveEta size={24} />
          <Link to={"/book-a-service"}>Book a test drive</Link>
        </div>
      </div> */}

      <div className="fixed bottom-5 right-5">
        <a
          href="https://api.whatsapp.com/send/?phone=9912341531&text&type=phone_number&app_absent=0"
          target="_blank"
        >
          <div className="bg-green-600 p-2.5 rounded-full animate-bounce">
            <img src={whatsappSvg} className="h-6 w-6" />
          </div>
        </a>
      </div>
    </>
  );
}

export default App;
