import React from "react";
import Header from "../components/navbar/Header";
import Footer from "../components/footer/Footer";
import { offers } from "../json/offers";
import OfferCard from "../components/Offercard";
import { Link } from "react-router-dom";

const Offers = () => {
  return (
    <>
      <Header />
      <img
        src={require("../assets/banners/1.webp")}
        className="w-full lg:h-[650px] object-cover"
        loading="lazy"
        alt="Offers "
      />
      <div className="max-w-screen-xl mx-auto px-5 my-10">
        <p className=" capitalize font-semibold lg:text-3xl text-center my-10">
          Offers
        </p>
        <div className="grid sm:grid-cols-2 md:grid-cols-3  gap-5">
          {offers.map((item, index) => (
            <OfferCard
              key={index}
              title={item?.name}
              price={item?.discount}
              banner={item?.pic}
            />
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Offers;
