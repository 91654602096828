import { useState } from "react";
import { Link } from "react-router-dom";
import Header from "../components/navbar/Header";
import Footer from "../components/footer/Footer";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Gallery() {
  const awards = [
    require("../assets/awards/1.jpeg"),
    require("../assets/awards/2.jpeg"),
    require("../assets/awards/3.jpeg"),
    require("../assets/awards/4.jpeg"),
    require("../assets/awards/5.jpeg"),
    require("../assets/awards/6.jpeg"),
    require("../assets/awards/7.jpeg"),
    require("../assets/awards/8.jpeg"),
    require("../assets/awards/9.jpeg"),
    require("../assets/awards/10.jpeg"),
    require("../assets/awards/11.jpeg"),
    require("../assets/awards/12.jpeg"),
    require("../assets/awards/13.jpeg"),
    require("../assets/awards/14.jpeg"),
  ];

  const outlets = [
    {
      name: "Chevella",
      pic: require("../assets/outlets/chevella.jpeg"),
    },
    {
      name: "Hydernagar",
      pic: require("../assets/outlets/hydernagar.jpeg"),
    },
    {
      name: "Kondapur",
      pic: require("../assets/outlets/kondapur.jpeg"),
    },
    {
      name: "Madhapur",
      pic: require("../assets/outlets/madhapur.jpeg"),
    },
    {
      name: "Manikonda",
      pic: require("../assets/outlets/manikonda.jpeg"),
    },
    {
      name: "Moinabad",
      pic: require("../assets/outlets/moinabad.jpeg"),
    },
    {
      name: "Shabad",
      pic: require("../assets/outlets/shabad.jpeg"),
    },
    {
      name: "Vikarabad",
      pic: require("../assets/outlets/vikarabad.jpeg"),
    },
  ];

  const bikeOutlets = [
    {
      name: "Erragadda",
      pic: require("../assets/outlets/bike/erragada.jpeg"),
    },
    {
      name: "Kondapur",
      pic: require("../assets/outlets/bike/kondapur.jpeg"),
    },
    {
      name: "Krishnanagar",
      pic: require("../assets/outlets/bike/krishnanagar.jpeg"),
    },
    {
      name: "Madinaguda",
      pic: require("../assets/outlets/bike/madinaguda.jpeg"),
    },
    {
      name: "Somajiguda",
      pic: require("../assets/outlets/bike/somajiguda.jpeg"),
    },
  ];

  const [channel, setChannel] = useState(0);

  const channels = ["Awards", "Outlets", "Bike Outlets"];

  return (
    <>
      <Header />
      <img
        src={require("../assets/banners/1.webp")}
        className="w-full lg:h-[650px] object-cover"
        loading="lazy"
        alt="inusrance banner"
      />
      <div className="shadow sm:px-0 px-4">
        <p className="mx-auto container py-4">
          <Link to="/">Home</Link> / Gallery
        </p>
      </div>
      <div className="container mx-auto py-16 px-5">
        <div className="my-8">
          <div className="flex items-center justify-center gap-5 border-gray-200/80">
            {channels.map((item, index) => (
              <div
                className={classNames(
                  channel === index &&
                    "bg-transparent border-b-2 border-red-600",
                  "font-medium cursor-pointer p-2"
                )}
                onClick={() => setChannel(index)}
                key={index}
              >
                {item}
              </div>
            ))}
          </div>
        </div>

        {channel === 0 && (
          <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
            {awards.map((img) => (
              <img
                src={img}
                key={img}
                alt={`1${img}`}
                className=" h-[300px] w-full object-cover rounded"
              />
            ))}
          </div>
        )}

        {channel === 1 && (
          <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5">
            {outlets.map((item) => (
              <div>
                <img
                  src={item?.pic}
                  key={item?.name}
                  alt={`1${item.pic}`}
                  className=" h-[300px] w-full object-cover rounded"
                />
                <p className="font-semibold text-center">{item.name}</p>
              </div>
            ))}
          </div>
        )}

        {channel === 2 && (
          <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5">
            {bikeOutlets.map((item) => (
              <div>
                <img
                  src={item?.pic}
                  key={item?.name}
                  alt={`1${item.pic}`}
                  className=" h-[300px] w-full object-cover rounded"
                />
                <p className="font-semibold text-center">{item.name}</p>
              </div>
            ))}
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}

export default Gallery;
