import { Link } from "react-router-dom";

import CurrencyFormmater from "./CurrencyFormatter";

const OfferCard = ({ id, title, price, banner }) => {
  return (
    <div className="rounded-lg overflow-hidden border group border-gray-200/70 ">
      <div className="bg-slate-100/80 overflow-hidden">
        <img
          src={banner}
          className="w-full object-cover duration-150 group-hover:scale-110 h-[250px]"
          loading="lazy"
          alt=""
        />
      </div>
      <div className="mx-7">
        {/* title */}
        <div className="py-3 space-y-1 flex items-center justify-between">
          <div>
            {/* price */}
            <p className="text-sm">Save up to</p>
            <h4 className="font-semibold">{CurrencyFormmater.format(price)}</h4>
          </div>
          <h5 className="font-medium text-black/80 text-xl">{title}</h5>
        </div>
        <div className="h-px w-full bg-gray-200/80"></div>
        <div className="flex items-center justify-between py-3">
          <div>
            <Link to={`/vehicles/${id}`} className="text-blue-500/90 text-sm">
              View Details
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferCard;
