import { useParams } from "react-router-dom";
import { models } from "../json";
import CarCard from "../components/Card";
import Header from "../components/navbar/Header";
import Footer from "../components/footer/Footer";

const Channel = () => {
  const { id } = useParams();
  return (
    <>
      <Header />
      <img
        src={require("../assets/banners/1.webp")}
        className="w-full lg:h-[650px] object-cover"
        loading="lazy"
        alt="inusrance banner"
      />
      <div className="max-w-screen-xl mx-auto px-5 my-20">
        <p className=" capitalize font-semibold lg:text-3xl text-center my-10">
          {id} Cars
        </p>
        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
          {models.map(
            (item, index) =>
              item.category === id && (
                <CarCard
                  key={index}
                  id={item?.id}
                  tag={item?.tag}
                  banner={item?.pic}
                  title={item?.name}
                  price={item?.price}
                  fuel={item?.fuel}
                  transmission={item?.transmission}
                  engine={item?.cc}
                />
              )
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Channel;
