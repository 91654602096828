import Header from "../components/navbar/Header";
import Footer from "../components/footer/Footer";
import { Link } from "react-router-dom";

function Awards() {
  const awards = [
    require("../assets/awards/1.jpeg"),
    require("../assets/awards/2.jpeg"),
    require("../assets/awards/3.jpeg"),
    require("../assets/awards/4.jpeg"),
    require("../assets/awards/5.jpeg"),
    require("../assets/awards/6.jpeg"),
    require("../assets/awards/7.jpeg"),
    require("../assets/awards/8.jpeg"),
    require("../assets/awards/9.jpeg"),
    require("../assets/awards/10.jpeg"),
    require("../assets/awards/11.jpeg"),
    require("../assets/awards/12.jpeg"),
    require("../assets/awards/13.jpeg"),
    require("../assets/awards/14.jpeg"),
  ];
  return (
    <>
      <Header />
      <img
        src={require("../assets/banners/1.webp")}
        className="w-full lg:h-[650px] object-cover"
        loading="lazy"
        alt="inusrance banner"
      />
      <div className="shadow sm:px-0 px-4">
        <p className="mx-auto container py-4">
          <Link to="/">Home</Link> / Awards
        </p>
      </div>
      <div className="container mx-auto py-16 px-5">
        <p className="text-red-500 font-semibold mb-5 md:text-3xl text-center">
          Awards
        </p>
        <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
          {awards.map((img) => (
            <img
              src={img}
              key={img}
              alt={`1${img}`}
              className=" h-[300px] w-full object-cover rounded"
            />
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Awards;
