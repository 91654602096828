import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { LuMoveRight } from "react-icons/lu";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Autoplay, Navigation } from "swiper/modules";

function Slider({ sliders }) {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  return (
    <>
      <Swiper
        spaceBetween={30}
        // autoplay={{
        //   delay: 2500,
        //   disableOnInteraction: false,
        // }}
        navigation={{
          nextEl: navigationNextRef.current,
          prevEl: navigationPrevRef.current,
        }}
        onBeforeInit={(swiper) => {
          swiper.params.navigation.prevEl = navigationPrevRef.current;
          swiper.params.navigation.nextEl = navigationNextRef.current;
        }}
        loop={true}
        modules={[Autoplay, Navigation]}
        className="mySwiper slider-home h-full md:h-[500px]"
      >
        {sliders?.map((item, i) => (
          <SwiperSlide key={i}>
            <img
              src={item.img}
              loading="lazy"
              className="w-full h-full object-cover"
              alt={i}
            />
          </SwiperSlide>
        ))}

        <div
          ref={navigationPrevRef}
          className="absolute left-3 sm:left-10 top-1/2 sm:top-1/2 z-10 bg-black/35 text-white rounded-full p-1 sm:p-3 cursor-pointer"
        >
          <LuMoveRight className="rotate-180" />
        </div>
        <div
          ref={navigationNextRef}
          className="absolute right-3 sm:right-10 top-1/2 sm:top-1/2 z-10 bg-black/35 text-white rounded-full p-1 sm:p-3 cursor-pointer"
        >
          <LuMoveRight />
        </div>
      </Swiper>
    </>
  );
}

export default Slider;
