import { useState } from "react";
import { Link } from "react-router-dom";
import Header from "../components/navbar/Header";
import Footer from "../components/footer/Footer";
import { GoLocation } from "react-icons/go";
import { LuPhone } from "react-icons/lu";
import { SlTag } from "react-icons/sl";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const outlets = [
  {
    name: "Arena Sales - Kondapur",
    address: "KONDAPUR MAIN ROAD",
    phone: 9912341452,
    tag: "sales",
  },
  {
    name: "Truevalue - Hydernagar",
    address: "2-11 BESIDE MYSAMMA TEMPLE HYDER NAGER KUKATPALLI-500085",
    phone: 9912341526,
    tag: "sales & service",
  },
  {
    name: "Arena Sales - Manikonda",
    address:
      "SRV NO 135/A,135/AANEAR ANDHRA BANK BESIDE RTATHNA DEEP STORE MANIKOND-500089",
    phone: 9912340941,
    tag: "sales & service",
  },
  {
    name: "Truevalue - Madhapur",
    address: "100 FEAT RAOD MADHAPUR TRUE VALUE",
    phone: 9912345869,
    tag: "sales & service",
  },

  {
    name: "MDS & Sales - Moosapet",
    address:
      "MARUTHI DRVING SCHOOL GREEN HILLS RESEDENCE AGI COLONY MOOSAPET HYD 500018",
    phone: 9912345869,
    tag: "sales",
  },
  {
    name: "Arena Sales - Monibad",
    address: "CHILKUR ROAD AZIZ HAGER X ROAD MOINABAD TS-500075",
    phone: 9912345868,
    tag: "sales",
  },

  {
    name: "Arena Sales - Vikarabad",
    address: "YENNAPALLY, RR-DT NH -4 HYD -5011101",
    phone: 9912341674,
    tag: "sales",
  },
  {
    name: "Arena Sales - Chevella",
    address: "SERV NO 17,A, MADAL CHEVELLA TS 501503",
    phone: 9912341528,
    tag: "sales",
  },
  {
    name: "Arena Sales - Shabad",
    address: "SERV NO 467/1 SHABAD TS 509217",
    phone: 7997994919,
    tag: "sales",
  },
  {
    name: "Commerical MDS - Suncity",
    address: "BANDLA GUDA MAIN ROAD",
    phone: 9912222582,
    tag: "sales",
  },
  {
    name: "Arena & Nexa Sales - Kothapet",
    address: "H.NO 16-2741/C/8/2 ASMANGADH ROAD MALAKPET HYD 500059",
    phone: 9912345828,
    tag: "sales",
  },
  {
    name: "Nexa Sales - Malakpet",
    address: "H.NO 16-2741/C/8/2 ASMANGADH ROAD MALAKPET HYD 500059",
    phone: 9912345830,
    tag: "sales",
  },

  {
    name: "Suzuki Sales - Kondapur",
    address:
      "SURVEY NO: 17 & 18, Kondapur Main Road, opp. KIMS Hospital, Hanuman Nagar, Shilpa Hills, Kondapur, Telangana 500084",
    phone: 7997994901,
    tag: "sales & service",
  },

  {
    name: "Suzuki Sales - Madinaguda",
    address:
      "OPP TO STATE BANK OF INDIA, MADEENAGUDA, NATIONAL HIGHWAY, beside BATA SHOWROOM, Chanda Nagar, Hyderabad, Telangana 500049",
    phone: 9912342658,
    tag: "sales",
  },

  {
    name: "Suzuki Sales - Erragadda",
    address: "Vikaspuri, Erragadda, Hyderabad, Telangana 500018",
    phone: 9133392315,
    tag: "sales",
  },
  {
    name: "Suzuki Sales - Somajiguda",
    address:
      "6-3-1086/A, Ground Floor, ANK's Tower, Raj Bhavan Rd, opp. to Induslnd Bank, Somajiguda, Hyderabad, Telangana 500082",
    phone: 9133392314,
    tag: "sales",
  },
  {
    name: "Suzuki Sales & Services - Krishna Nagar",
    address:
      "B-26, Krishna Nagar Main Rd., Sri Krishna Nagar, Yousufguda Basti, Yousufguda, Hyderabad, Telangana 500045",
    phone: 9133392309,
    tag: "sales & services",
  },
  {
    name: "Suzuki Sales - Vikarabad",
    address: "opp to - Maruti showroom, shivareddy pet, vikarabad.",
    phone: "9281092462",
    tag: "sales",
  },

  {
    name: "Suzuki Bikes Service - Vikarabad",
    address:
      "Survey No 64, Main Road, Kondapur, Hyderabad - 500084 (Kondapur Signal)",
    phone: "7997994868",
    tag: "service",
  },

  {
    name: "Suzuki Bikes Service - Somajiguda",
    address: "Matha Nagar, 6-3-667/420, Somajiguda, Hyderabad - 500082",
    phone: "9133392309",
    tag: "service",
  },

  {
    name: "Suzuki Bikes Service - Erragadda",
    address:
      "H No 8-3-166/2/1 Ground Floor, Dharma Mansion, Erragadda, Hyderabad - 500018",
    phone: "9133392301",
    tag: "service",
  },
  {
    name: "Suzuki Bikes Service - Madinaguda",
    address:
      "Poojitha Pride, 20 & 21, Ramakrishna Nagar, Hafeezpet, Madeenaguda, Hyderabad, Telangana 500049",
    phone: "991239892",
    tag: "service",
  },
];

function Outlets() {
  return (
    <>
      <Header />
      <img
        src={require("../assets/banners/1.webp")}
        className="w-full lg:h-[650px] object-cover"
        loading="lazy"
        alt="inusrance banner"
      />
      <div className="shadow sm:px-0 px-4">
        <p className="mx-auto container py-4">
          <Link to="/">Home</Link> / Outlets
        </p>
      </div>
      <div className="container mx-auto py-16 px-5">
        <h4 className="text-center font-semibold lg:text-2xl my-8">Outlets</h4>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5">
          {outlets.map((item, index) => (
            <div
              className="rounded-lg overflow-hidden border group border-gray-200/70 "
              key={index}
            >
              <div className="bg-slate-100/80 overflow-hidden"></div>
              <div className="mx-7">
                <div className="py-3">
                  <h5 className="font-medium text-black/80 text-lg">
                    {item?.name}
                  </h5>

                  <div className="space-y-2 mt-3 text-black/80 text-sm ">
                    <div className="flex items-center justify-start space-x-4">
                      {/* <GoLocation /> */}
                      <p className="lowercase">{item?.address}</p>
                    </div>
                    <div className="flex items-center justify-start space-x-4">
                      <LuPhone />
                      <a href={`tel:${item?.phone}`}>{item?.phone}</a>
                    </div>
                    <div className="flex items-center justify-start space-x-4">
                      <SlTag />
                      <p>{item?.tag}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Outlets;
